/*
Language: PENMAN
Description: PENMAN encoding is a lightweight predicate-argument structure format.
Author: Jon Cai <jon.z.cai@colorado.edu>
Website: https://aclanthology.org/2020.acl-demos.35/
Category: common, linguistics, amr
*/

export default function(hljs) {

    return {
      contains: [
        {
          className: 'attribute',
          begin: /\s:[^\s]+/,
          relevance: 10,
          starts: {
            end: /\s/,
            contains: [
              {
                className: 'value',
                begin: /[^:()\s]+/,
              },
            ],
          },
        },
        {
          className: 'attribute',
          begin: /\s:[^\s]+/,
        },
        {
          className: 'string',
          begin: /"([^"\\]|\\.)*"/,
        },
        {
          className: 'variable',
          begin: /\(\s/,
          end: /\s\)/,
          contains: [
            hljs.NUMBER_MODE,
            {
              className: 'variable',
              begin: /[a-zA-Z_$][0-9a-zA-Z_$]*/,
            },
          ],
        },
      ],
    };
}