import { LRParser } from '@lezer/lr';
import { LRLanguage, indentNodeProp, delimitedIndent, foldNodeProp, foldInside, LanguageSupport } from '@codemirror/language';
import {completeFromList, snippetCompletion} from "@codemirror/autocomplete"
import { styleTags, tags } from '@lezer/highlight';
//
import {
    Completion,
    CompletionContext,
    CompletionResult,
    autocompletion,
    closeCompletion,
    startCompletion,  
} from "@codemirror/autocomplete";
import { EditorView, ViewUpdate } from '@codemirror/view';
// import { EditorState, EditorView, basicSetup } from "@codemirror/basic-setup";
import { debounce } from "lodash";
import axios from 'axios';
import { getAutoCompleteSnippet } from '../services/auth';
// This file was generated by lezer-generator. You probably shouldn't edit it.
const parser = LRParser.deserialize({
    version: 14,
    states: "#`OVQPOOOOQO'#Cb'#CbO_QPO'#CaOjQPO'#C^OOQO'#Ch'#ChQOQPOOOOQO'#C_'#C_OOQO'#Cc'#CcOOQO,58{,58{OoQPO,58xOtQQO1G.dOOQO'#C`'#C`OyQPO7+$OOOQO'#Cd'#CdO!RQPO<<GjOOQO<<Gj<<GjOOQO-E6b-E6bOOQOAN=UAN=U",
    stateData: "!Z~OZOS~O^RObPO~O^RO_UOcVO~O_UO~O`YO~OaZO~O]_ObPO~O]aObPO~O",
    goto: "!S]PP^djmtayPPP!PQSORWQQWQRXRR[YQSOT][^VQO[^Q^[R`^RTO",
    nodeNames: "⚠ AMR Node Variable NodeLabel Edge EdgeLabel Constant",
    maxTerm: 19,
    skippedNodes: [0],
    repeatNodeCount: 1,
    tokenData: "%d~RZXYtYZt]^tpqtrsyxy!kyz!p!P!Q!u![!]!z#R#S$P#T#o$u~yOZ~~|R!c!}!V#R#S!V#T#o!V~!YSrs!f!c!}!V#R#S!V#T#o!V~!kOc~~!pO^~~!uO]~~!zO`~~!}Q!c!d#T#T#o#l~#WP!t!u#Z~#^P!i!j#a~#dP!Q![#g~#lOb~~#qQb~!Q![#w#T#o#l~#|Pb~!Q![#wQ$USaQ}!O$b!Q![$m#R#S$P#T#o$PQ$gQaQ}!O$b!Q![$mQ$rPaQ!Q![$mR$|SaQ_P}!O$b!Q![%Y#R#S$P#T#o$PR%aPaQ_P!Q![%Y",
    tokenizers: [0, 1],
    topRules: {"AMR":[0,1]},
    tokenPrec: 0
});

const AMRLanguage = LRLanguage.define({
    parser: parser.configure({
        props: [
            indentNodeProp.add({
                Application: delimitedIndent({ closing: ")", align: false })
            }),
            foldNodeProp.add({
                Application: foldInside
            }),
            styleTags({
                EdgeLabel: tags.keyword,
                NodeLabel: tags.function(tags.punctuation),
                String: tags.string,
                Variable: tags.variableName,
                Constant: tags.controlOperator,
                "( )": tags.paren
            })        
        ]
    }),
    languageData: {
        commentTokens: { line: "#" }
    }
});

// export const AMRCompletion = AMRLanguage.data.of({
//   autocomplete: 
//     completeFromList([
//         {label: ":ARG0", type: "keyword"},
//         {label: ":ARG1", type: "keyword"},
//         {label: ":ARG3", type: "keyword"},
//         {label: ":mode", type: "keyword"},
//         {label: ":mod", type: "keyword"},
//         {label: ":op1", type: "keyword"},
//         {label: ":op2", type: "keyword"},
//         {label: ":op3", type: "keyword"},
//         {label: ":polarity", type: "keyword"},
//         {label: ":manner", type: "keyword"},
//         {label: ":location", type: "keyword"},
//         {label: ":degree", type: "keyword"},
//         snippetCompletion('(${var} / make-01 \n\t:ARG0 (${var1} / ${concept1}) \n\t:ARG1 (${var2} / ${concept2})', {label: 'make-01'})
//   ])
// })

const amr_relations = [':ARG0', ':ARG1', ':ARG2', ':ARG3', ':ARG4', ':ARG5', ':ARG6', ':location', ,':accompanier', 'age', ':beneficiary', ':concession', ':condition', ':degree', ':destination', ':duration', ':extent', ':frequency', ':instrument', ':li', ':location', ':manner', ':mod', ':name', ':ord', ':part',':polarity', ':poss', ':purpose', ':quant', ':source', ':subevent', ':time', ':topic', ':value', ':op1', ':op2', ':op3', ':op4', ':op5', ':calendar', ':century', ':day', ':dayperiod', ':decade', ':era', ':month', ':quarter', ':season', ':timezone', ':weekday', ':year', ':unit', ':direction', ':scale'];

// ws explore code ===============
function infoDomCreater(completion){
    console.log("completion: ", completion);
    const lines = completion.infolines;
    const snippet_dom = document.createElement("div");
    snippet_dom.className = "container";
    for (var i=0; i <= lines.length - 1;i++) {
        const line_dom = document.createElement('div');
        const line_text = document.createTextNode(lines[i]);
        line_dom.appendChild(line_text);
        snippet_dom.appendChild(line_dom);
    }
    return snippet_dom;
};

function destroy_func(){
    return null;
};

async function autocompleteAPIcall(context, word) {

    const data = {context_obj: context, word_obj: word};
    console.log("autocompleteAPIcall, data: ", data);
    if(word.text.startsWith(":")){
        const filtered_rels = amr_relations.filter(str => str.startsWith(word.text));
        const autocomplete_results = filtered_rels.map((r) => ({label: r, type: 'keyword'}));
        console.log("autocomplete_results: ", autocomplete_results);
        return autocomplete_results;
    } else {
        try{
            const response = await getAutoCompleteSnippet(data);  
            console.log("response: ", response);
            const snippet = snippetCompletion('(${var} / make-01 \n\t:ARG0 (${var1} / ${concept1}) \n\t:ARG1 (${var2} / ${concept2})', {label: 'make-01', info: 'make-01-snippet'});
            // const withsnippet = [...response.data, snippet];
            // return withsnippet;

            const propbanksnippets = response.map((snippet_dict) => {
                // const lines = snippet_dict.info.split('|');

                return snippetCompletion(snippet_dict.tmp_str, {label: snippet_dict.label, info: infoDomCreater, infolines: snippet_dict.info});
                // return snippetCompletion(snippet_dict.tmp_str, {label: snippet_dict.label, info: snippet_dict.info});
            });
            console.log("propbanksnippets:");
            console.log(propbanksnippets);
            console.log("-----------------");
            return propbanksnippets;
        } catch(error) {
            console.log("error: ", error);
            return [
                {label: "match", type: "keyword"},
                {label: "hello", type: "variable", info: "(World)"},
                {label: "magic", type: "text", apply: "⠁⭒*.✩.*⭒⠁", detail: "macro"}
            ];
        }        
    }

}

async function myCompletions(context) {
  let word = context.matchBefore(/[A-Za-z0-9:_-]*/);
  if (word.from === word.to && !context.explicit){
    return null;
  } else {
    const options_ls = await autocompleteAPIcall(context, word);
    
    return {
        from: word.from,
        options: options_ls
    }; 
  }
}

// const debouncedStartCompletion = debounce((view) => {
//   startCompletion(view);
// }, 300);

// function customCompletionDisplay() {
//   return EditorView.updateListener.of(({ view, docChanged }) => {
//     if (docChanged) {
//       // when a completion is active each keystroke triggers the
//       // completion source function, to avoid it we close any open
//       // completion inmediatly.
//       closeCompletion(view);

//       debouncedStartCompletion(view);
//     }
//   });
// }

const extensions = [
  autocompletion({
    activateOnTyping: false,
    override: [myCompletions],
  }),
  // customCompletionDisplay(),
];
// ===============================

function AMR() {
    // return new LanguageSupport(AMRLanguage, [AMRCompletion]);
    return new LanguageSupport(AMRLanguage, extensions);
    // return new LanguageSupport(AMRLanguage, [ls]);
}

export { AMR, AMRLanguage };
