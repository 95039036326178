import React, { useState, useEffect, useRef } from 'react';
import {ExtraEditor, TextEditor, AMREditor} from './Editor';
// import RoleList from "./RoleList";
import RoleListPop from "./RoleListPop";
// import ReleaseListPop from "./ReleaseListPop";

import useLocalStorage from '../hooks/useLocalStorage';

import * as data from './propbank.json';
import WorksetManager from "./Uploader";

import SplitPane, {Pane} from "react-split-pane";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
// import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import TextPanel from './TextPanel';
import './App.css';
import Pagination from '@mui/material/Pagination';
import usePagination from "./Pagination";
import { default as annotationData } from "./MOCK_DATA.json";
import axios from 'axios';
import NewWindow from 'react-new-window';
import PropbankRoleListPop from './PropbankRoleListPop';
import ReleaseListNWPop from './ReleaseListNWPop';
import Fade from '@mui/material/Fade';
// var propbank = data.PropBank;
// var rolesets = [];
// for (let i = 0; i < propbank.length; i++) {
//   for (var j = 0; j < propbank[i].predicates.length; j++) {
//     rolesets.push(...propbank[i].predicates[j].rolesets);
//   }
// }

// for New menu compoenents
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Search, SearchIconWrapper, StyledInputBase } from './menustyle'; 
// end of New menu components
import { LOGOUT } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postAMRData, getReleaseSearch, getPropbankSearch, getParserQuery } from '../services/auth';
// const BASE_API_URL = 'http://127.0.0.1:5000';


const role_set_init = [{"roleset_id":"examples", "examples":[{"example_name": "1st example", "example_text":"first example text", "args": [{"arg_text": "example", "f":"", "n":"0"}], "rel":{"rel_text":"st"}}]}];
const release_amr_init = [{"snt": "this is a test sent", "amr": "(t / this\n  :equal (s / snt\n    :mod (t / test)\n  )\n)"}];

function App() {
  const [text, setText] = useLocalStorage('text', '');
  const [css, setCss] = useLocalStorage('css', '');
  const [js, setJs] = useLocalStorage('js', '');

  const dispatch = useDispatch();
  // const [checked, setChecked] = useState(true);
  const navigate = useNavigate();


  const [heightValue, setHeightValue] = useState("485px");
  const [keyword, setKeyword] = useState('');
  const [rskeyword, setRSKeyword] = useState('');
  // const [searchword, setSearchWord] = useState('');
  
  const [rolesetsFilter, setRolesetsFilter] = useState(role_set_init);
  const [releaseFilter, setReleaseFilter] = useState(release_amr_init);
  const [uploadShow, setUploadShow] = useState(false);

  const [loading, setLoading] = useState(false);
  const [propbankQueryReady, setPropbankQueryReady] = useState(false);
  const [releaseQueryReady, setReleaseQueryReady] = useState(false);
  const [anchorElDrawer, setAnchorElDrawer] = useState(null);

  const filestrings = JSON.stringify({currentFileName: annotationData});  
  const [allAnnotationData, setAllAnnotationData] = useLocalStorage('AMRAnnotationData', filestrings);

  const [page, setPage] = useState(1);

  // const [perPageSents, setPerPageSents] = useState(1);
  
  const [totalPages, setTotalPages] = useState(annotationData.length);
  const allAnnotationRef = useRef(annotationData);

  const [currentPageText, setCurrentPageText] = useState(allAnnotationRef.current[0]['text']);
  const [currentPageAMRs, setCurrentPageAMRs] = useState(allAnnotationRef.current[0]['AMR']);
  const [currentPageTags, setCurrentPageTags] = useState(allAnnotationRef.current[0]['AMR_aligns']);
  const [currentVars, setCurrentVars] = useState(allAnnotationRef.current[0]['AMR_vars']);
  const [currentPageParserAMRs, setCurrentPageParserAMRs] = useState('');


  const handleDrawerClick = (event) => {
    setAnchorElDrawer(event.currentTarget);
  };
  const handleDrawerClose = () => {
    setAnchorElDrawer(null);
  };  

  const handleAddNewFile = (newfile_json) => {
    setAllAnnotationData(newfile_json);
    console.log(allAnnotationData);
  };

  const handleOnChange = (event) => {
    console.log("handleOnChange: event.target.value: ", event.target.value);
    setKeyword(event.target.value);
  };

  const RShandleOnChange = (event) => {
    setRSKeyword(event.target.value);
  };
  const handleOnSubmit = (event) => {
    event.preventDefault();
    // setSearchWord(keyword);
    propbankQuery(keyword);
  };
  const RShandleOnSubmit = (event) => {
    event.preventDefault();
    // setSearchWord(keyword);
    releaseQuery(rskeyword);
  };  
  const handleNextBatchSubmit = (event) => {
    event.preventDefault();
    // setRenderNextBatch(current_index);
  };

  // const [fileName, setFileName] = useState("Upload a File");

  const handleFileSubmit = (event) => {
    // event.preventDefault();
    //logic to create new user...
  };

  const handleDownload = (event) => {

  };

  const handleChangeCurrentWorkSet = (data, lastIndex) => {
    // do something to update the text

    allAnnotationRef.current = data;

    
    setCurrentPageText(allAnnotationRef.current[lastIndex]['text']);
    setCurrentPageAMRs(allAnnotationRef.current[lastIndex]['AMR']);
    setCurrentPageTags(allAnnotationRef.current[lastIndex]['AMR_aligns']);
    setCurrentVars(allAnnotationRef.current[lastIndex]['AMR_vars']);
    setTotalPages(data.length);
    setPage(lastIndex+1);
    
  };


  // console.log("cur_text: ", cur_text);
  const propbankQueryUnload = () => {
    setPropbankQueryReady(false);
  };

  const releaseQueryUnload = () => {
    setReleaseQueryReady(false);
  }

  const handlePageChange = async (e, newp) => {
    console.log('e.target: ', e.target);
    console.log('e.target.value: ', e.target.value);
    console.log('newp: ', newp);
    const newPageAnnot = {'id': allAnnotationRef.current[page-1].id,
                          'text': currentPageText, 
                          'AMR': currentPageAMRs,
                          'AMR_aligns': currentPageTags,
                          'AMR_vars': currentVars
                         };
    // const currentFileAllAData = allAnnotationRef.current.map((dict, index) => index === (currentPage-1) ? newPageAnnot : dict );
    allAnnotationRef.current[page-1] = newPageAnnot;
    
    // setAllAnnotationData('allAnnotationData', JSON.stringify({currentFileName: currentFileAllAData}));

    // update individual AMRData
    console.log('allAnnotationRef.current[page-1]: ', allAnnotationRef.current[page-1]);

    const resp = await postAMRData({lastIndex: page, AMRData: newPageAnnot}).catch((e)=> {console.log(e);});
    if (resp !== undefined) {
      setPage(newp);
      // before changing save current progress

      setCurrentPageText(allAnnotationRef.current[newp-1]['text']);
      setCurrentPageAMRs(allAnnotationRef.current[newp-1]['AMR']);
      setCurrentPageTags(allAnnotationRef.current[newp-1]['AMR_aligns']);
      setCurrentVars(allAnnotationRef.current[newp-1]['AMR_vars']);

      // ==============REACTIVATE parser server line==============
      // parserQuery(allAnnotationRef.current[p-1]['text']);      
    } else {
      alert('failed to push to DB');
    }
  };

  async function parserQuery(cur_text) {

    try{
      const response = await getParserQuery(cur_text);
      console.log("response: ", response);
      const amr_string = JSON.parse(response);
      setCurrentPageParserAMRs(amr_string.amr);
      console.log("currentPageParserAMRs: ", currentPageParserAMRs);
    }catch(error){
      console.log('parser error: ', error);
    }
  }
  async function propbankQuery(keyword) {


      try{
          setLoading(true);
          const response = await getPropbankSearch(keyword);  
          console.log("response: ", response);
          
          setRolesetsFilter(response);
          setPropbankQueryReady(true);
          console.log("in propbank Query")
          setLoading(false);
          
      } catch(error) {
          console.log("error: ", error);
          setRolesetsFilter(role_set_init);
          setLoading(false);
      }
  }
  async function releaseQuery(keyword) {

      try{
          setLoading(true);
          const response = await getReleaseSearch(keyword);  
          console.log("release query response: ", response);
          
          setReleaseFilter(response);
          setReleaseQueryReady(true);
          setLoading(false);
          
      } catch(error) {
          console.log("error: ", error);
          setReleaseFilter(release_amr_init);
          setLoading(false);
      }
  }  

  const handleLogout = () => {
    setAnchorElDrawer(null);
    dispatch({ type: LOGOUT });
    navigate('/login', { replace: true });
  };

  return (
    <>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="inherit" sx={{height: 60}}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            aria-controls={Boolean(anchorElDrawer) ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={Boolean(anchorElDrawer) ? 'true' : undefined}
            onClick={handleDrawerClick}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorElDrawer}
            open={Boolean(anchorElDrawer)}
            onClose={handleDrawerClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={() => {
              setUploadShow(true); 
              handleDrawerClose();
            }}>
              Work Sets
            </MenuItem>
            <MenuItem onClick={handleDrawerClose}>Download</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>          
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            AMR Editor
          </Typography>
          <Box sx={{ flexGrow: 1}}>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Pagination
                count={totalPages}
                color="primary"
                variant="outlined"
                page={page}
                siblingCount={1}
                onChange={handlePageChange}
              />
            </Box>
          </Box>
          <form onSubmit={handleOnSubmit}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Propbank…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleOnChange}
              />
            </Search>
          </form>
          <form onSubmit={RShandleOnSubmit}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Release…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e) => RShandleOnChange(e)}
              />
            </Search>
          </form>
          {propbankQueryReady && (<PropbankRoleListPop roleList={rolesetsFilter} unloadFunc={propbankQueryUnload}/>
          )}
          {releaseQueryReady && (<ReleaseListNWPop sentAMRs={releaseFilter} unloadFunc={releaseQueryUnload}/>
          )}          
        </Toolbar>
      </AppBar>
    </Box>
    <WorksetManager
      show={uploadShow}
      onHide={() => setUploadShow(false)}
      changeCurrentWorkset={handleChangeCurrentWorkSet}
    />
      <SplitPane
        style={{ marginTop: "60px" }}
        split="horizontal"
        minSize={"100%"}
        onDragFinished={(height) => {
          setHeightValue(`${height - 40}px`);
        }}
      >
      <SplitPane split="vertical" minSize={"33%"}>

        <TextPanel text={currentPageText} initValue={currentPageTags} setValue={setCurrentPageTags} amrVars={currentVars}/>
        <SplitPane split="vertical" minSize={"50%"}>
          <AMREditor
            height={heightValue}
            value={currentPageAMRs}
            setValue={setCurrentPageAMRs}
            amrVars={currentVars}
            setAMRVars={setCurrentVars}
          />
          <ExtraEditor
            height={heightValue}
            value={currentPageParserAMRs}
            setValue={setCurrentPageAMRs}
          />
          </SplitPane>
        </SplitPane>
      </SplitPane>
    </>
  )
}

export default App;
