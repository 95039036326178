import React from "react";
// import AceEditor from "react-ace";
// import ace from "ace-builds/src-noconflict/ace";
// import "ace-builds/src-noconflict/mode-javascript";
// import "ace-builds/src-noconflict/mode-amr";

// import "ace-builds/src-noconflict/mode-html";
// import "ace-builds/src-noconflict/mode-css";

// import "ace-builds/src-noconflict/theme-monokai";

// import "ace-builds/src-noconflict/ext-language_tools";
// import "./amr";
import "./mode-amr";
// import "ace-builds/src-noconflict/snippets/javascript";
// import "ace-builds/src-noconflict/snippets/html";
// import "ace-builds/src-noconflict/snippets/amr";
import styles from "./editors.module.css";
// import 'ace-builds/webpack-resolver';

// -------------------------------------------------------- 
// import { setCompleters } from "ace-builds/src-noconflict/ext-language_tools";
// import { addCompleter } from "ace-builds/src-noconflict/ext-language_tools";

// import { useRef } from "react";
// import { useEffect } from "react";
// -------------------------------------------------------- 
import { useState } from 'react';
// import { useCodeMirror } from '@uiw/react-codemirror';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { sublime } from '@uiw/codemirror-theme-sublime';
import { monokai } from "./themes/monokai";
import { palenight } from "./themes/palenight";
import { AMR } from "./penman-grammar-parser-highlight";
import { indentationMarkers } from "@replit/codemirror-indentation-markers";
import { useDispatch, useSelector } from 'react-redux';
import { AMR_VARS } from '../store/actions';
import './custom-completionInfo-style.css';
// Define the extensions outside the component for the best performance.
// If you need dynamic extensions, use React.useMemo to minimize reference changes
// which cause costly re-renders.
const code = "(m / make-01 :ARG0 (p / person ))"
const extensions = [AMR(), indentationMarkers()];
// -------------------------------------------------------- 
const amr_var_regex = /\(\s*(\w\d*)\s*\//ig;
const areSetsEqual = (a, b) => a.size === b.size && [...a].every(value => b.has(value));
export const ExtraEditor = (props) => {
  return <Editor mode="javascript" title={"parser suggestion"} {...props} />;
};

export const TextEditor = (props) => {
  return <Editor mode="text" title={"Text"} {...props} />;
};

export const AMREditor = (props) => {
  return <Editor mode="amr" title={"AMR"} {...props} />;
};

const checkNewVar = (amr_string, existing_amr_vars) => {
  const cur_amr_vars_ls = [...amr_string.matchAll(amr_var_regex)];
  const cur_amr_vars = cur_amr_vars_ls.map((x) => x[1]);
  const cur_amr_vars_set = new Set(cur_amr_vars);
  const existing_amr_vars_set = new Set(existing_amr_vars);
  if (areSetsEqual(cur_amr_vars_set, existing_amr_vars_set)) {
    return [true, []];
  } 
  return [false, Array.from(cur_amr_vars_set)];
}
const Editor = ({ mode, value, setValue, title, height, amrVars, setAMRVars }) => {
  // const editor = useRef();
  // const { setContainer } = useCodeMirror({
  //   container: editor.current,
  //   extensions,
  //   value: code,
  // });

  // useEffect(() => {
  //   if (editor.current) {
  //     setContainer(editor.current);
  //   }
  // }, [editor.current]);
  // console.log("in Editor, value: ", value);
  // const amrVars = useSelector((state) => state.amr.amrVars);
  const dispatch = useDispatch();
  const onChange = React.useCallback((newValue, viewUpdate) => {
    // console.log("newValue:", newValue);
    if( amrVars !== undefined ){
      const new_flag_tuple = checkNewVar(newValue, amrVars);
    
      if(!new_flag_tuple[0]){
        // dispatch({
        //   type: AMR_VARS, 
        //   payload: { updatedAMRVars: new_flag_tuple[1] }
        // });
        setAMRVars(new_flag_tuple[1]);
      }
    }
    
    // dataRef.current["MOCK_DATA"][indexRange[0]]['amr'] = newValue;

    setValue(newValue);
  }, []);
  return  (
    <div className={styles.editorContainer}>
      <div className={styles.editorTitle}>{title}</div>
      {/*<div ref={editor}/>*/}
      <CodeMirror
        className="custom-completionInfo"
        value={value}
        height="100%"
        theme={sublime}
        extensions={extensions}
        onChange={onChange}
      />
    </div>
  );
};
