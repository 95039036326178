import React, { useEffect, useRef } from 'react';
import hljs from 'highlight.js/lib/core';
import 'highlight.js/styles/docco.css'; // Import your preferred code style
import penmanLanguage from './penmanLanguage'; // Import the custom Penman language definition

// Register the custom Penman language with highlight.js
hljs.registerLanguage('penman', penmanLanguage);

const PenmanHighlight = ({ code }) => {
  const codeRef = useRef(null);

  useEffect(() => {
    // Apply syntax highlighting to the code block
    if (codeRef.current) {
      hljs.highlightBlock(codeRef.current);
    }
  }, [code]);

  return (
    <pre>
      <code ref={codeRef} className="penman">
        {code}
      </code>
    </pre>
  );
};

export default PenmanHighlight;
