import React from 'react';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import Divider from './Divider';
// import SyntaxHighlighter from 'react-syntax-highlighter';
// import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import NewWindow from 'react-new-window';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { red, deepOrange, amber, lightGreen, cyan, blue, purple } from '@mui/material/colors';
// import { CodeBlock, dracula, monokai } from "react-code-blocks";
import hljs from 'highlight.js/lib/core';
// import hljs from 'highlight.js';
import 'highlight.js/styles/docco.css';
import penmanLanguage from './penmanLanguage';
import PenmanHighlight from './PenmanHighlight';

const colors = [red[500], deepOrange[500], amber[400], lightGreen[400], cyan[400], blue[400], purple[300]];
// SyntaxHighlighter.registerLanguage('penman', penmanLanguage);
hljs.registerLanguage('penman', penmanLanguage);

const preprocessCode = (code) => {
  const lines = code.split('\n');
  // Calculate the common indentation (minimum leading spaces) to remove
  const minIndentation = lines.reduce((minIndent, line) => {
    if (line.trim() !== '') {
      const lineIndent = line.match(/^\s*/)[0].length;
      return Math.min(minIndent, lineIndent);
    }
    return minIndent;
  }, Infinity);
  // Remove the common indentation from each line
  const processedCode = lines.map((line) => line.slice(minIndentation)).join('\n');
  return processedCode;
};

const ReleaseListNWPop = ({sentAMRs=[], unloadFunc}) => {
  console.log("sentAMRs: ", sentAMRs);
  
  const renderCode = ({ language, value }) => {
    return (
      <code className={`language-${language}`} style={{ whiteSpace: 'pre-wrap' }}>
        {value}
      </code>
    );
  };
  return (
    <>
    <NewWindow
      title="LDC Release Lookup"
      onUnload={unloadFunc}
      fetures={{left: 200, top: 200, width: 400, height: 400}}      
    >
    { sentAMRs.map((sntAMR,index) => {
        if (sntAMR) {
          var color_index = index % colors.length;
          var class_choice = colors[color_index];
          const divider = index > 0 ? (<span></span>) : null;
          // const AMRlines = sntAMR.amr.split("\n ");
          // console.log("AMRlines: ", AMRlines);
          // const processedAMRlines = AMRlines.map((amrline) => amrline.replace(`     `,  `  `));
          // const finalAMRstr = processedAMRlines.join("\n ");
          const finalAMRstr = preprocessCode(sntAMR.amr);


          console.log("finalAMRstr: ", finalAMRstr);
          return (
          <>
          {divider}
          <Card sx={{minWidth: 390, padding: '10px', margin: '2px'}} variant="outlined">
            <CardContent>
              
              <p className="line-p"><span className="example-name-text" style={{color: class_choice}}>sentence:</span>{sntAMR.snt.slice(7)}</p>
              <Divider spacing={1}>AMR</Divider>

              <PenmanHighlight code={finalAMRstr} />
         
            </CardContent>
          </Card>
          </>
          )
         }
         return null
    }) }
    </NewWindow>
    </>
  );
}

export default ReleaseListNWPop