import React, { useEffect } from "react";
import { TextAnnotate, AnnotateTag } from "react-text-annotate-blend";
import { useDispatch, useSelector } from 'react-redux';
import { AMR_TAGS } from '../store/actions';
/*
Stateful example with blended tags not allowed
*/
import RadioButtonGroup from 'react-custom-radio-buttons-group';
import './textpenal.css';
const color_book: string[] = [
  "#7498f0",
  "#63c6e0",
  "#c2fdfd",
  "#fecede",
  "#95d873",
  "#b9ee93",
  "#f6c430",
  "#f9ed19",
  "#ec68a2",
  "#fdb0da",
  "#f2f0ae",
  "#b4cbff",
  "#d9e5ff",
  "#c1f0f9",
  "#cdadd8",
  "#ebcef3",
  "#e392bd",
  "#5dc1b8",
  "#97d9cb",
  "#bcfbea",
  "#d9f5c4",
  "#f9c981",
  "#fefb95",
  "#d44c88",
  "#fde7ef",
  "#b67eb5",
  "#d173ce",
  "#977eb8",
  "#f7a78d",
  "#fbdcad",

];

const init: AnnotateTag[] = [
  // {
  //   start: 0,
  //   end: 5,
  //   text: "Harry",
  //   tag: "tagC",
  //   color: color_book[0],
  // },
  // {
  //   start: 6,
  //   end: 10,
  //   text: "made",
  //   tag: "tagB",
  //   color: color_book[1],
  // },
  // {
  //   start: 120,
  //   end: 124,
  //   text: "each",
  //   tag: "tagC",
  //   color: "#4b46cd",
  // },
];

const demoText =
  "Harry made a cake.";

type COLOR_TYPE = {
  [key: string]: string;
  // tagA: string;
  // tagB: string;
  // tagC: string;
};

function TextPanel({text, initValue, setValue, amrVars}) {
  // const [value, setValue] = React.useState(init);
  const [tag, setTag] = React.useState("");
  // console.log("text: ", text);


  // const amrVars = useSelector((state) => state.amr.amrVars);
  // console.log('amrVars:', amrVars);
  // console.log('initValue: ', initValue);
  // const initColors = amrVars.map((amrvar, index) => ({amrvar: color_book[index]}));
  const initColors = Object.assign({}, ...amrVars.map((x, index) => ({[x]: color_book[index]})));

  // console.log("initColors: ", initColors);
  const [tagcolors, setTagColors] = React.useState(initColors);

  const dispatch = useDispatch();
  useEffect(() => {
    
  const newColors = Object.assign({}, ...amrVars.map((x, index) => ({[x]: color_book[index]})));
  setTagColors(newColors);
    // eslint-disable-next-line
  }, [amrVars]);

  const handleChange = (value: AnnotateTag[]) => {
    console.log('TextPanel handleChange value: ', value);
    setValue(value);
    // dispatch({
    //   type: AMR_TAGS,
    //   payload: { value }
    // });
    // console.log("TextPanel handleChange dataRef.current: ", dataRef.current);
    // console.log("indexRange: ", indexRange);
    // console.log("dataRef.current: ", dataRef.current);

    // dataRef.current["MOCK_DATA"][indexRange[0]]['tags'] = value;
  };

  // const COLORS: COLOR_TYPE = {
  //   tagA: color_book[0],
  //   tagB: color_book[1],
  //   tagC: color_book[2],
  // };
  const handleTagPicking = (e)=>{
    setTag(e.target.value);
  };

  return (
    <div style={{ padding: 20 }}>
      <div>
        <TextAnnotate
          style={{
            fontSize: "1.5rem",
          }}
          content={text}
          onChange={handleChange}
          value={initValue}
          getSpan={(span) => ({
            ...span,
            tag: tag,
            color: tagcolors[tag],
          })}
        />
      </div>

      {/*<select onChange={(e) => setTag(e.target.value)}>
        {Object.keys(COLORS).map((label) => (
          <option key={label} value={label}>
            {label}
          </option>
        ))}
      </select>*/}
      <div className="radio-button-group">
        <RadioButtonGroup
          onChange={handleTagPicking}
          hide={true}
          values={Object.keys(tagcolors)}
        />
      </div>
      <p>&nbsp;</p>
      
    </div>
  );
}

export default TextPanel;