import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// action - state management
import { LOGIN, LOGOUT, REFRESH } from './actions';

export const initialState = {
    refresh: '',
    access: '',
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

// -----------------------|| ACCOUNT REDUCER ||-----------------------//

const accountReducer = (state = initialState, action) => {
    switch (action.type) {

        case REFRESH: {
            const { accessTK } = action.payload;
            return {
                ...state,
                access: accessTK
            };
        }
        case LOGIN: {
            const { userDict, accessTK, refreshTK } = action.payload;
            console.log('in accountReducer: ', userDict);
            console.log('in accountReducer: ', accessTK);
            console.log('in accountReducer: ', refreshTK);
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                access: accessTK,
                refresh: refreshTK,
                user: userDict
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isInitialized: false,
                isLoggedIn: false,
                access: '',
                refresh: '',
                user: null
            };
        }
        default: {
            return { ...state };
        }
    }
};


const reducer = combineReducers({
    account: persistReducer(
        {
            key: 'account',
            storage,
            keyPrefix: 'CAMRA-'
        },
        accountReducer
    )
});

export default reducer;